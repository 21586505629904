import React, { useEffect, useState } from "react";
import { calculateMatchPercentage, extractNumber, getUserDetails, isFormComplete, notifyUser } from "../../Utils/general";
import { Popconfirm, Checkbox } from "antd";
import moment from "moment";
import { serverRequest } from "../../Utils/server_request";

export const ProjectDetails = ({ data }) => {

    const [loading, setLoading] = useState(false)
    const [milestoneInputs, setMilestoneInputs] = useState({});

    // Initialize state when data is available
    useEffect(() => {
        if (data?.milestones) {
        const initialInputs = {};
        data.milestones.forEach((milestone) => {
            initialInputs[milestone.id] = {
            milestone_id: milestone.id,
            hours: "",
            budget: "",
            due_date: "",
            };
        });
        setMilestoneInputs(initialInputs);
        }
    }, [data?.milestones]);

    // Handle input changes for each milestone
    const handleInputChange = (milestoneId, field, value) => {
        setMilestoneInputs((prevInputs) => ({
        ...prevInputs,
        [milestoneId]: {
            ...(prevInputs[milestoneId] || {}),
            milestone_id: milestoneId,
            [field]: value,
        },
        }));
    };

    const handleSubmit = () => {
        const payload = {milestones: Object.values(milestoneInputs)};
        if(isMilestoneFormComplete(milestoneInputs)){
            setLoading(true)
            serverRequest('POST', `projects/${data?.id}/request`, payload).then(result => {
                if(!result?.success){
                    setLoading(false)
                    notifyUser('error', result?.message, 'Error')
                }else{
                    setLoading(false)
                    notifyUser('success', result?.message, 'Success')
                }
            }).catch(error => {
                setLoading(false)
                notifyUser('error', error?.message, 'Error')
            })
        }else{
            notifyUser('error', 'Enterr all fields budget, hours and due date to proceed', 'Error')
        }
    };

    let isMilestoneFormComplete = (form) => {
        return Object.values(form).every(milestone => {
            // Convert each field to string to safely use trim
            return String(milestone.hours).trim() !== '' &&
                String(milestone.budget).trim() !== '' &&
                String(milestone.due_date).trim() !== '';
        });
    };

    const handleCheckboxChange = async (e, milestoneId) => {
    if (e.target.checked) {
      try {
        serverRequest('POST', `milestones/${milestoneId}/update`).then(result => {
            if(result?.success){
                notifyUser('success', result?.message, 'Milestone Update')
            }
        }).catch(error => {
            notifyUser('error', error.message, 'Error')
        })
      } catch (error) {
        console.error("Error updating milestone:", error);
      }
    }
  };


    return (
        <div className="p-4">
            <p className="lead">{data?.title}</p>
            <small>Date: {moment(data?.created_at).format('LL')}</small>
             <p className="text-small-message mt-3">{data?.description}</p>
             <hr/>
             <div className="bg-light-yellow p-4 mb-5">
                <p><i className="bi bi-broadcast-pin"/> Skill required for project</p>
                <p>{data?.skill}</p>
             </div>
            {data?.milestones.map((milestone, index) => 
                <div key={index} className="mb-5">
                    <div className="row align-items-center">
                        <div className="col"><p className="text-small-message"
                        style={{ textDecoration: milestone.completed ? "line-through" : "none" }}><i className="bi bi-geo"/> Milestone {index+1}</p></div>
                        <div className="col">{!milestone.completed && (<Checkbox onChange={e => handleCheckboxChange(e, milestone?.id)} style={{ fontSize: "10px" }}>Complete </Checkbox>)}</div>
                    </div>
                    <p className="text-small-message">{milestone?.title}</p>
                    <div className="bg-light-blue p-4">
                        <p className=""><i className="bi bi-hourglass-split"/>  Tasks</p>
                        <ul>{milestone.milestone_task.map((task, index) => <li key={index}>{task?.task}</li> )}</ul>
                    
                        {/* Inputs for hours, budget, and due date */}
                        {data?.status == 'pending' ? 
                            <div className="row mt-4">
                                <div className="col-4">
                                    <div className="form-group">
                                    <label>Hours</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={milestoneInputs[milestone.id]?.hours || ""}
                                        onChange={(e) =>
                                        handleInputChange(milestone.id, "hours", e.target.value)
                                        }
                                    />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                    <label>Budget/hr ($)</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={milestoneInputs[milestone.id]?.budget || ""}
                                        onChange={(e) =>
                                        handleInputChange(milestone.id, "budget", e.target.value)
                                        }
                                    />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="form-group">
                                    <label>Due Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={milestoneInputs[milestone.id]?.due_date || ""}
                                        onChange={(e) =>
                                        handleInputChange(milestone.id, "due_date", e.target.value)
                                        }
                                    />
                                    </div>
                                </div>
                            </div>
                        : ''}
                        
                    </div>
                    
                </div>
            )}

            {data?.status === 'approved' ? 
            <div>
                <div className="bg-light-blue p-3 mb-3">
                    <p className="mb-0 fw-bold">Summary of this Project</p>
                </div>
                {data.milestones.map((milestone, index) => {
                    return (
                    <div key={index} className="mb-4">
                        <h6 className="fw-bold">Milestone {index + 1}</h6>
                        <div className="row">
                        <div className="col-6">
                            <p>Tasks</p>
                        </div>
                        <div className="col-6 text-end">
                            <p>Total: {milestone.milestone_task.length}</p>
                        </div>
                        <div className="col-6">
                            <p>Hours Required</p>
                        </div>
                        <div className="col-6 text-end">
                            <p>Total: {milestone.hours}</p>
                        </div>
                        {/* <div className="col-6">
                            <p>Professional Assigned</p>
                        </div> */}
                        {/* <div className="col-6 text-end">
                            <p>{milestone?.participant?.name}</p>
                        </div> */}
                        {/* <div className="col-6">
                            <p>Hourly Rate</p>
                        </div> */}
                        {/* <div className="col-6 text-end">
                            <p>{milestone?.participant?.subProfessionalProfile?.compensation}</p>
                        </div> */}
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                        <h6 className="fw-bold">Total Expense of Milestone {index + 1}</h6>
                        <h6 className="text-success">Total: ${parseFloat(milestone.budget) * milestone.hours}</h6>
                        </div>
                        <hr />
                    </div>
                    );
                })}
            </div> 
            : '' }

            <Popconfirm
                title="Submit Proposal"
                description="Are you the details provided are accurate?"
                onConfirm={handleSubmit}
                onCancel={null}
                okText="Yes"
                cancelText="No"
            >
            {data?.status == 'pending' ? <button className="btn btn-primary w-100 fw-bold btn-small" disabled={loading}>{loading ? 'Submitting...' : 'Request to take on project'}</button> : ''}
            </Popconfirm>
        </div>
    )
}