import React, {useEffect, useState} from "react";
import {Progress, Select, Spin, Tag} from "antd";
import { serverRequest } from "../../Utils/server_request";
import { notifyUser } from "../../Utils/general";

export const ProfilePage = () =>  {

    const [stepper, setStepper] = useState(1);
    const [loading, setLoading] = useState(false);
    const [skills, setSkills] = useState([]);
    const [profile, setProfile] = useState(null);
    const [addedSkills, setAddedSkills] = useState([]);
    const [form, setForm] = useState({company_description: '', skills: []});

    const TOTAL_PAGES = 2;

    const toggleLoading = () => setLoading(prev => !prev)

    useEffect(() => {
        fetchSkills()
        fetchProfile()
    }, [])

    const handleContinue = () => {
       setStepper(prev => Math.min(prev + 1, TOTAL_PAGES));
    };

    const handleBack = () => {
        setStepper(prev => Math.max(prev - 1, 1)); // Prevent going below 0
    };

    const exitPage = () => { window.location.href = '/dashboard' }

    const handleChange = (value) => {
        setAddedSkills(value);
    };

    const fetchSkills = () => {
        serverRequest('GET', 'professional-skills').then(result => {
            if(result?.data?.length  > 0){
                let _data = []
                result?.data.map(item => {
                    let d = {
                        label: item.name,
                        value: item.id
                    }
                    _data.push(d)
                })
                setSkills(_data)
            }
        })
    }

    const fetchProfile = () => {
        serverRequest('GET', 'professional-profile').then(result => {
            if(result.data){
                setProfile(result.data)
                setForm(prev => ({ ...prev, company_description: result.data.company_description, skills: result.data.gaps}))
            }
        })
    }

    const submitForm = () => {
        toggleLoading()
        serverRequest('POST', 'professional/create-profile', {company_description: form.company_description, expertise: addedSkills}).then(result => {
            toggleLoading()
            if(result?.profile){
                notifyUser('success', result?.message, 'Success')
                fetchProfile()
            }else{
                notifyUser('error', 'Problem creating profile!', 'Error')
            }
        })
    }

    return (
        <div>
            <p className="fw-bold lead">Complete your profile</p>
            <div className="progress-box">
                <Progress percent={(stepper / 2) * 100} showInfo={false} strokeColor="#6bc497" size={{ height: 18 }} />
            </div>
            <div className="row mt-3 mb-5">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <button className="btn btn-white shadow fw-bold p-2" onClick={handleBack}>Back</button>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <button className="btn btn-white shadow fw-bold float-lg-end float-md-end p-2" onClick={stepper === 2 ? submitForm : handleContinue}>{stepper === 2 ? "Save" : "Continue"}</button>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <p className={`d-flex mb-0 p-3 ${stepper === 1 ? 'fw-bold' : ''}`}>
                        <div className={`${stepper >= 1 ? 'number-circle-active' : 'number-circle'}`}>1</div>
                        Basic Info
                    </p>
                    <p className={`d-flex mb-0 p-3 ${stepper === 2 ? 'fw-bold' : ''}`}>
                        <div className={`${stepper === 2 ? 'number-circle-active' : 'number-circle'}`}>2</div>
                        About the Company
                    </p>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <div className="mx-auto mobile-full-width" style={{width: '80%'}}>
                        <Spin spinning={loading}>
                            <div className="">
                            {/* Content for actions */}
                            {stepper === 1 ? 
                                <div>
                                    <label htmlFor="">Tell us few lines about your Company</label>
                                    <textarea className="form-control" rows="10" value={form.company_description} onChange={e => setForm(prev => ({ ...prev, company_description: e.target.value }))}>{form?.company_description}</textarea>
                                    <small>What you do?</small> <br/>
                                    <small>What servies your Company provide?</small>
                                    {/* <div align='center'>
                                        <button className="btn btn-dark btn-small mt-4 w-50 mobile-full-width">Save</button>
                                    </div> */}
                                </div>
                                : ''}
                                
                            
                                {stepper === 2 ? 
                                <div>
                                    <p className="lead text-center">What does your company specialize in?</p>
                                    <p className="text-small text-center">Choose Top 4</p>

                                    <Select
                                        mode="multiple" size="large"
                                        allowClear style={{ width: '100%' }} placeholder="Please select" onChange={handleChange}
                                        options={skills}
                                        />

                                        <div className="mt-5">
                                            {form.skills.map(item => 
                                                <Tag className="mb-3">{item.name}</Tag>
                                            )}
                                        </div>
                                        {/* <div align='center'>
                                            <button className="btn btn-dark btn-small mt-5 w-50 mobile-full-width">Save</button>
                                        </div> */}
                                </div>
                                : ''}
                            </div>    
                        </Spin>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}