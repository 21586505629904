import React, {useEffect, useState} from "react";
import { serverRequest } from "../../Utils/server_request";
import { notifyUser } from "../../Utils/general";
import { Input, Space, Table, Tag } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

export const GrantedBusiness = () => {
const navigate = useNavigate();
const [data, setData] = useState([])
const [loading, setLoading] = useState(false);
const [searchText, setSearchText] = useState('');
const [originalData, setOriginalData] = useState([]);

const toggleLoading = () => setLoading(prevState => !prevState)

useEffect(() => {
     fetchData()
},[])

    const fetchData = () => {
        toggleLoading()
        serverRequest('GET', `professional/data-room/get-businesses`).then(result =>{
            if (result){
                toggleLoading()
                setData(result?.data);
                setOriginalData(result?.data);
            }
        }).catch(error => {
            notifyUser('error', error.message, 'Data fetching failed')
        })
    }

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchText(value);

        const filteredData = originalData.filter(item =>
            Object.values(item).some(val =>
                String(val).toLowerCase().includes(value)
            )
        );
        setData(filteredData);
    };

    const columns = [
        { title: 'Business Name', dataIndex: 'business_name', key: 'business_name', onFilter: (value, record) => record.business_name.includes(value), },
        { title: 'name', dataIndex: 'name', key: 'name', onFilter: (value, record) => record.createdBy.includes(value), },
        {title: 'Status', dataIndex: 'phone', key: 'phone', render: (phone) => (<Tag color={phone !== '020000000000' ? 'green' : 'red'}>{phone !== '020000000000' ? 'Active' : 'Inactive'}</Tag>), },
        { title: 'email', dataIndex: 'email', key: 'email', onFilter: (value, record) => record.email.includes(value), },
        // { title: 'phone', dataIndex: 'phone', key: 'phone' },
         { title: 'Action',render: (_, record) => <button className='btn btn-dark btn-small' onClick={() => navigate('/data-room/files', {state: {business_id: record.id, business_name: record.business_name}})}>Vist Room</button> },
    ];

  return (
     <div>
            <div className="p-4">
                <Space style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <p className="lead">Businesses Granted You Access</p>
                    </div>
                    <Input
                            prefix={<SearchOutlined />}
                            placeholder="Search"
                            style={{ width: 200 }}
                            value={searchText}
                            onChange={handleSearch}
                            allowClear
                        />
                </Space>
                <Table
                    columns={columns}
                    dataSource={data}
                    rowKey={(record) => record.id}
                    loading={loading} />
             </div>
        </div>
  )
}