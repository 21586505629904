import React, {useState} from "react";
import anvillistLogo from "../../assets/anvillist-logo.png";
import {Input, Spin} from 'antd';
import { useNavigate } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import {isFormComplete, notifyUser} from "../../Utils/general";
import {serverRequest} from "../../Utils/server_request";
import secureLocalStorage from "react-secure-storage";

export const Login = () => {
  const navigate = useNavigate();

  const [form, setForm] = useState({ email: '', password: ''})
  const [loading, setLoading] = useState(false)

  const toggleLoading = () => setLoading(prevState => !prevState)

  const changeHandler = (e) => setForm({...form, [e.target.name]: e.target.value})

  const submitLogin = () => {
    if (isFormComplete(form)) {
      toggleLoading()
      serverRequest('POST', 'login', form).then(result => {
        if (result?.access_token) {
          secureLocalStorage.setItem('loggedInToken', result?.access_token)
          secureLocalStorage.setItem('loggedInUser', JSON.stringify(result?.data));

          const user_details = {
            "logged_in_user_id": result.data.id,
            "user_access_token": result.access_token,
            "user_name": result.data.name,
          }
          sessionStorage.setItem("user_details", JSON.stringify(user_details));
           window.location.href = '/dashboard'
        }else{
          toggleLoading()
          notifyUser('error', result?.message, 'Login failed')
        }
      });
    } else {
      notifyUser('error', 'All fields are required!', 'Validation Error')
    }
  }

  return (
      <div>
        <div className="w-50 mx-auto mobile-full-width mobile-padding-top">
          <div align='center'>
            <h2 className="fw-bold">Welcome to Anvillist 👋</h2>
            <small>Your trusted partner on the path to investment readiness.</small>
          </div>

          <div style={{ paddingTop:'10%' }} className='w-75 mx-auto mobile-full-width'>
            <h2 className="fw-bold mb-3">Login</h2>
            <p className="lead mb-4">Welcome back!</p>

            <Spin spinning={loading}>
              <div className="form-group mb-4">
                <label htmlFor="email">Email</label>
                <Input type="email" name='email' placeholder="Enter business email" onChange={changeHandler} value={form.email}/>
              </div>
              <div className="form-group mb-4">
                <label htmlFor="email">Password</label>
                <Input.Password name="password" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                placeholder="Enter password" className='mb-2' onChange={changeHandler} value={form.password}/>
                <small className='fw-bold' style={{cursor:'pointer',color:'#3B82F6'}} onClick={() => navigate('/forgotPassword')}>Forgot Password?</small>
              </div>
              <button className="btn btn-primary btn-small fw-bold w-100" onClick={submitLogin}>Login</button>
            </Spin>

            <hr className='mt-5'/>
            
            <div className="row align-items-center">
              <div className="col"><p className="mb-0 fw-bold">Don’t have an account?</p></div>
              <div className="col"><button className="btn btn-primary btn-small float-end" onClick={() => navigate('/signUp')}>Sign Up</button></div>
            </div>
          </div>
        </div>
      </div>
  )
}