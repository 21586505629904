import React from "react";
import {createBrowserRouter, Route} from "react-router-dom";
import App from "./App";
import {Login} from "./pages/authentication/Login";
import {ForgotPassword} from "./pages/authentication/ForgotPassword";
import {ResetPasswordOTP} from "./pages/authentication/ResetPasswordOTP";
import {SignUp} from "./pages/authentication/SignUp";
import {VerifyAccountOTP} from "./pages/authentication/VerifyAccountOTP";
import RecommendedProfessionalProfilePage from "./Components/Dashboard/RecommendedProfessionalProfilePage"
import SubscriptionPlans from "./pages/pricing/SubscriptionPlans";
import PaymentProcessingPage from "./pages/pricing/PaymentProcessingPage";
import MeetingsPage from "./pages/meeting/MeetingsPage";
import { MessagePage } from "./pages/messages/messagePage";
import { WorkforcePage } from "./pages/workforce/WorkforcePage";
import { BusinessListPage } from "./pages/business/BusinessListPage";
import { DashboardPage } from "./pages/dashboard/dashboardPage";
import { ProfilePage } from "./pages/profile/ProfilePage";
import { ProjectPage } from "./pages/project/projectPage";
import { CreateProject } from "./pages/project/createProject";
import { GrantedBusiness } from "./pages/data-room/GrantedBusiness";
import { RoomFileList } from "./pages/data-room/RoomFileList";

export const router = createBrowserRouter([
    { path: "/", element: <App />},
    { path: "/signUp", element: <SignUp />},
    { path: "/login", element: <Login />},
    { path: "/forgotPassword", element: <ForgotPassword />},
    { path: "/resetPassword", element: <ResetPasswordOTP />},
    { path: "/verify-account", element: <VerifyAccountOTP />},
    { path: '/dashboard', element: <DashboardPage /> },
    { path: '/meetings', element: <MeetingsPage /> },
    { path: '/business', element: <BusinessListPage /> },
    { path: '/projects', element: <ProjectPage /> },
    { path: '/projects/create', element: <CreateProject /> },
    { path: '/workforce', element: <WorkforcePage /> },
    { path: '/recommended-profile', element: <RecommendedProfessionalProfilePage /> },
    { path: '/inbox', element: <MessagePage /> },
    { path: '/pricing', element: <SubscriptionPlans /> },
    {path: "/payment-success", element: <PaymentProcessingPage />},
    {path: "/api/v1/payment-success", element: <PaymentProcessingPage />},
    {path: "/profile", element: <ProfilePage />},
    {path: "/data-room", element: <GrantedBusiness />},
    {path: "/data-room/files", element: <RoomFileList />},
]);
