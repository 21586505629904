import React, {useEffect, useState} from "react";
import {serverRequest} from "../../Utils/server_request";
import {Spin, Select, Table, Pagination, Tag, Popconfirm} from "antd";
import {getUserDetails, isFormComplete, notifyUser} from "../../Utils/general";
import {ModalDialog} from "../../Components/modal";

const MeetingsPage = () => {

    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 15, total: 0 });
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [businesses, setBusinesses] = useState([])
    const [businessIds, setProfessionalIds] = useState([])
    const [updateBusinessIds, setUpdateBusinessIds] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const [meetingForm, setMeetingForm] = useState({title: '', description: '', start_date: '', start_time: ''})

    const toggleModal = () => setIsOpen(prevState => !prevState)
    const toggleLoading = () => setLoading(prevState => !prevState)

    useEffect(() => {
        fetchMeetings()
        getAllBusinesses()
    },[])

    const getAllBusinesses = () => {
        serverRequest('GET', 'all-users?type=business')
            .then(result => {
                let _selectData = []
                setBusinesses(result.data)
                result.data.map(item => {
                    let d = {
                        label: item.business_name,
                        value: item.email
                    }
                    _selectData.push(d)
                })
                setBusinesses(_selectData)
            })
    }

    const handleChange = (value) => { setProfessionalIds(value) }

    const changeHandler = (e) => setMeetingForm({...meetingForm, [e.target.name]: e.target.value})

    const setupMeeting = () => {
      if (isFormComplete(meetingForm)){
          if (businessIds.length > 0){
              toggleLoading()
              const payload = {
                  start_time: meetingForm.start_time,
                  end_time: '10:00pm',
                  date: meetingForm.start_date,
                  title: meetingForm.title,
                  emails: businessIds,
                  description: meetingForm.description
              }
              serverRequest('POST', 'meetings/create', payload).then(result => {
                  toggleLoading()
                  if (result.success){
                      toggleModal()
                      notifyUser('success', 'Meeting created successfully!', 'Meeting Created')
                  }else{
                      notifyUser('error', 'Problem creating meeting!', 'Meeting Failed')
                  }
              }).catch(error => {
                  toggleLoading()
                  notifyUser('error', error.message, 'Error')
              })
          }else{
              notifyUser('error', 'Please select one or more businesses to proceed!', 'Validation Error')
          }
      }else{
          notifyUser('error', 'Please fields are required to proceed!', 'Validation Error')
      }
    }

    const fetchMeetings = (page = 1) => {
        toggleLoading()
      serverRequest('GET', `meetings?page=${page}`).then(result => {
          toggleLoading()
          if (result){
              setData(result.data)
              setPagination({current: result.meta.current_page, pageSize: result.meta.per_page, total: result.meta.total})
          }
      })
    }

    const columns  = [
        {title: 'Title', dataIndex: 'title', key: 'title',},
        {title: 'Date', dataIndex: 'date', key: 'date',},
        {title: 'Start Time', dataIndex: 'start_time', key: 'start_time',},
        {title: 'Description', dataIndex: 'description',key: 'description',},
        {title: 'Meeting Link', dataIndex: 'meeting_link', key: 'meeting_link', render: (link) => link ? <a href={link} target="_blank" rel="noopener noreferrer">Join</a> : 'N/A',},
        {title: 'Organizer', dataIndex: ['user', 'name'], key: 'organizer',},
        {title: 'Participants', dataIndex: 'participants', key: 'participants',
            render: (participants) => ( participants.map(participant => (<Tag key={participant.user.id}>{participant.user.name}</Tag> )) ), },
        {title: 'Action', dataIndex: 'action', key: 'action', render: (_,record) => record?.user?.id === getUserDetails()?.id ?
                <Popconfirm title="Delete the meeting" description="Are you sure to delete this meeting?"
                    onConfirm={() => deleteMeeting(record.id)} onCancel={null} okText="Yes" cancelText="No"><p style={{cursor:'pointer'}}>Delete <i className="bi bi-trash text-danger"/></p></Popconfirm>: ''},
    ];

    const handlePageChange = (page) => { fetchMeetings(page) };

    const deleteMeeting = (id) => {
        toggleLoading()
      serverRequest('DELETE', `meetings/${id}`).then(result => {
          if (result){
              setLoading(false)
              fetchMeetings(pagination.current)
          }
      })
    }

    const updateMeeting = (record) => {
      setIsUpdate(true)
        setMeetingForm({title: record.title, description: record.description, start_date: record.start_date, start_time: record.start_time })
        let _participants = [];
      record.participants.map(item => _participants.push({ label: item.business_name, value: item.email }))
        setUpdateBusinessIds(_participants)
        toggleModal()
    }

    return (
        <div className="">
            {/*<MeetingsPageContainer user_details={user_details} />*/}
            <div className="row align-items-center">
                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <p className="lead">Meetings</p>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <button className="btn btn-primary float-end" onClick={toggleModal}>Setup Meeting <i className="bi bi-clock"/></button>
                </div>
                <div className="mt-5">
                    <Table
                        columns={columns}
                        dataSource={data}
                        rowKey="id"
                        loading={loading}
                        pagination={false}
                    />
                    <Pagination
                        current={pagination.current}
                        pageSize={pagination.pageSize}
                        total={pagination.total}
                        onChange={handlePageChange}
                        style={{ marginTop: '16px', textAlign: 'center' }}
                    />
                </div>
            </div>


            <ModalDialog show={isOpen} oncancel={toggleModal} size={600}>
                <div className="p-4">
                    <Spin spinning={loading}>
                        <div className="form-group mt-5 mb-3">
                            <label htmlFor="professional">Please select businesses you want invite</label>
                            <Select
                                mode="multiple"
                                allowClear
                                style={{width: '100%',}}
                                placeholder="Please select"
                                onChange={handleChange}
                                options={businesses}
                            />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="">Title of Meeting</label>
                            <input type="text" className="form-control" name='title' value={meetingForm.title} onChange={changeHandler}/>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="">Description of Meeting</label>
                            <textarea className="form-control" name='description' onChange={changeHandler}>{meetingForm.description}</textarea>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="">Date of Meeting</label>
                                    <input type="date" className="form-control" name='start_date' value={meetingForm.start_date} onChange={changeHandler}/>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="">Start time of Meeting</label>
                                    <input type="time" className="form-control" name='start_time' value={meetingForm.start_time} onChange={changeHandler}/>
                                </div>
                            </div>
                        </div>
                        <small>Note: All meeting sessions are 1-hour maximum</small>
                        <div align='center'>
                            <button className="btn btn-small btn-primary mt-4" onClick={setupMeeting}>{isUpdate ? 'Update':'Setup'} Meeting</button>
                        </div>
                    </Spin>
                </div>
            </ModalDialog>
        </div>
    )
};

export default MeetingsPage;