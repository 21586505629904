import React, {useEffect, useState} from "react";
import "./FixedScrollingMainScreenContent.css";
import shootingStarIcon from "../../assets/shootingStarIcon.png";
import selectedImg from "../../assets/selected.png";
import {Spin} from "antd";
import {serverRequest} from "../../Utils/server_request";
import {notifyUser} from "../../Utils/general";

const SubscriptionPlans = () => {

    const [packages, setPackages] = useState([])
    const [loading, setLoading] = useState(false)

    let professionalPackages = ['Professional Standard', 'Professional Premium']

    useEffect(() => {
        fetchPackages()
    },[])

    const fetchPackages = () => {
        setLoading(true)
      serverRequest('GET','subscription-packages').then(result => {
          setPackages(result.data)
          setLoading(false)
      }).catch(error  => {
          setLoading(false)
          notifyUser('error',error.message)
      })
    }


    const makePayment = (package_id) => {
        setLoading(true)
        serverRequest('GET', `checkout/${package_id}`).then(result => {
            setLoading(false)
            if (result?.payment_url){
                window.location.replace(result?.payment_url)
            }else{
                notifyUser('error', 'Sorry there was a problem initiating your payment', 'Payment Error')
            }
        }).catch(error  => {
            setLoading(false)
            notifyUser('error',error.message)
        })
    }

    return (
        <Spin spinning={loading}>
            <div className="w-75 mx-auto">
                <p className="lead">Choose your subscription plan</p>
                <div className="row">
                    {packages.filter(p => professionalPackages.includes(p.name)).map(_package =>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" key={_package.name}>
                            <div className="pricing-card shadow p-4">
                                <img src={shootingStarIcon} alt="" className="img-fluid" width='20px'/>
                                <h5 className="pricing-header mt-3">{_package.name}</h5>
                                {/*<small className="pricing-header-sub"></small>*/}

                                <div className="mt-4">
                                    <h5 className="pricing-header">${_package.price} </h5>
                                    <p className="pricing-header-sub">{_package?.subscription_period }</p>
                                </div>
                                <div className="mt-4 pricing-detail">
                                    {_package.details.split(',').map(item => <p className="mb-2 pricing-list"><img className="img-fluid" src={selectedImg} alt="" width='14px' style={{verticalAlign:'none'}}/> {item}</p> )}
                                </div>
                                <button className="btn btn-primary w-100 mt-4 fw-bold" onClick={() => makePayment(_package.id)}>Subscribe now</button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Spin>
    )
};

export default SubscriptionPlans;