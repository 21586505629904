import {getUserToken} from "./Utils/general";

function App() {

    if (getUserToken()){
        window.location.href = '/dashboard'
    }else{
        window.location.href = '/login'
    }
}

export default App;