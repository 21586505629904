import React, { useState, useEffect } from "react";
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import "../pages/pricing/PaymentProcessingLoadingScreen.css";
import baseUrl from "../Utils/baseUrl";
import {getUserToken, notifyUser} from "../Utils/general";

const PaymentProcessingLoader = ({ user_details = {}, sessionId = "" }) => {
    const [paymentProcessing, setPaymentProcessing] = useState(true);
    const [paymentText, setPaymentText] = useState("Failed to process payment!");
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            await fetchpaymentConfirmation();
            setPaymentProcessing(false);
        }
        fetchData();
    }, []);

    const fetchpaymentConfirmation = async () => {
        const maxRetries = 3;
        let attempt = 0;
        let final_api_response = null;

        while (attempt < maxRetries) {
            try {
                const response = await fetch(`${baseUrl}payment-success?session_id=${sessionId}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getUserToken()}`
                    }
                });

                if (response.status === 202 || response.ok) {
                    const data = await response.json();
                    // console.log("Successfully processed payment:", data);
                    openSuccessNotification({ message: "Payment processed successfully!" });
                    setPaymentStatus(true);
                    setPaymentText("Payment processed successfully!");
                    notifyUser('success', 'Payment completed successfully!')
                    navigate("/dashboard");
                }

                attempt++;
                console.warn(`Attempt ${attempt} failed, retrying...`);

                if (attempt < maxRetries) {
                    await new Promise(res => setTimeout(res, 1000)); // Wait 1 second before retrying
                } else {
                    final_api_response = response;
                }

            } catch (error) {
                console.error("An unexpected error occurred:", error);
                attempt++;
                if (attempt < maxRetries) {
                    await new Promise(res => setTimeout(res, 1000)); // Wait 1 second before retrying
                } else {
                    // If reached here, all attempts failed
                    openErrorNotification({ message: "Failed to process payment after multiple attempts" }, "Payment Error");
                }
            }
        }

        const error_data = await final_api_response.json();
        // If reached here, all attempts failed
        openErrorNotification(error_data, "Failed to process payment");
        setPaymentText(`${error_data.message}`);
    };

    const openErrorNotification = (notificationParameters, errorTitle = "Error") => {
        // console.loglog("notificationParameters = ", notificationParameters);
        api["error"]({
            message: errorTitle,
            description: notificationParameters.message,
            showProgress: true,
            pauseOnHover: true,
            duration: 0,
            onClose: close,
        });
    };

    const openSuccessNotification = (notificationParameters, successTitle = "Payment Success") => {
        // console.loglog("notificationParameters = ", notificationParameters);
        api["success"]({
            message: successTitle,
            description: notificationParameters.message,
            showProgress: true,
            pauseOnHover: true,
            duration: 0,
            onClose: close,
        });
    };

    const close = () => { navigate("/dashboard"); };

    return (
        <>
            {contextHolder}
            {paymentProcessing ? (
                <div className="spinner-container">
                    <div className="spinner"/>
                    <div className="loading-screen-text">Processing your payments...</div>
                </div>
            ) : (
                <div className="spinner-container">
                    {paymentStatus ? (
                        <div className="postAPIHitText successText">{paymentText}</div>
                    ) : (
                        <>
                            <div className="postAPIHitText">Failed to process payment!</div>
                            <div className="postAPIHitText failedText mt-3">{paymentText}</div>
                            <button className="btn btn-primary mt-4 fw-bold" onClick={() => window.location.href = '/pricing'}>Back to Pricing <i className="bi bi-credit-card"/></button>
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default PaymentProcessingLoader;