import React,{useState, useEffect} from "react";
import { Avatar, Tag, Progress, DatePicker, Select, Spin } from "antd";
import { serverRequest } from "../../Utils/server_request";
import { calculateMatchPercentage, extractNumber, notifyUser } from "../../Utils/general";
import { useNavigate } from "react-router-dom";

export const CreateProject = () => {
    const navigate = useNavigate()
  const [project, setProject] = useState({
    name: "",
    description: "",
    end_date: "",
    skills: [],
    client: "",
    business_user_id: "",
    milestones: [],
  });

  const [loading, setLoading] = useState(false);
  const [skills, setSkills] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [isSuggestProfessional, setIsSuggestProfessional] = useState(false);
  const [isSummary, setIsSummary] = useState(false);

  useEffect(() => {
    fetchSkills()
    fetechBusinesses()
  }, []);

  const  fetchSkills = () => {
    serverRequest('GET', 'professional-skills').then(result => {
        if(result.data){
            let _skills = []
            result.data.map(item => {
                let  d = {
                    label: item.name,
                    value: item.id
                }
                _skills.push(d)
            })
            setSkills(_skills)
        }
    })
  }

  const fetechBusinesses = () => {
    serverRequest('GET', 'all-users?type=business').then(result => {
        if(result.data){
            let _businesses = []
            result.data.map(item => {
                let  d = {
                    label: item.business_name,
                    value: item.email
                }
                _businesses.push(d)
            })
            setBusinesses(_businesses)
        }
    })
  }

  const handleAddMilestone = () => {
    setProject({
      ...project,
      milestones: [...project.milestones, { name: "", user_id: "", tasks: [] }],
    });
  };

  const handleAddTask = (index) => {
    const newMilestones = [...project.milestones];
    newMilestones[index].tasks.push({ name: "", budget: "", due_date: "" });
    setProject({ ...project, milestones: newMilestones });
  };

  const  fetchSuggestedProfessionals = () => {
    if(project.skills.length < 1) return notifyUser('error', 'Select skills for this project to continue', 'Validation Error')
    serverRequest('POST', 'professional-projects/suggested-professionals', {skills: project.skills}).then(result => {
        if(result.professionals){
            setProfessionals(result.professionals)
        }
    })
  }

  const handleSubmit = () => {
    setLoading(true)
    serverRequest('POST', 'professional-project/create', project).then(result => {
        setLoading(false)
        if(result.data){
            notifyUser('success', 'Project created successfully!', 'Project')
            navigate('/projects')
        }else{
             notifyUser('error', result.message, 'Error')
        }
    }).catch(error => {
        setLoading(false)
        notifyUser('error', error.message, 'Error')
    })
  };

  const assignProfessionalToMilestone = (milestoneIndex, professionalId) => {
        setProject((prev) => {
            const updatedMilestones = [...prev.milestones];
            updatedMilestones[milestoneIndex] = {
                ...updatedMilestones[milestoneIndex],
                user_id: professionalId, // Assign professional to milestone
            };
            setIsSummary(true)
            return { ...prev, milestones: updatedMilestones };
        });
    };

    const previewProjectSummary = () => {
        setIsSummary(true)
        setIsSuggestProfessional(true)
    }

  return (
    <div className="container mt-4">
      <p className="lead mb-5">Create Project</p>
      <div className="row">
        <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
            <p className="lead fw-bold">Project Details</p>
        
            <div className="mb-4">
                <label className="form-label">Project Name</label>
                <input type="text" className="form-control" value={project.name} onChange={(e) => setProject({ ...project, name: e.target.value })} />
            </div>

            <div className="mb-4">
            <label className="form-label">Description</label>
            <textarea className="form-control" rows="3" value={project.description} onChange={(e) => setProject({ ...project, description: e.target.value })}></textarea>
            </div>

            <div className="mb-4">
            <label className="form-label">End Date for Project</label>
            <DatePicker onChange={(date, dateString) => setProject({ ...project, end_date: dateString })} className="w-100" />
            </div>

            <div className="mb-4">
            <label className="form-label">Skills</label>
            <Select
                mode="multiple" allowClear style={{width: '100%',}} placeholder="Please select" size="large"
                onChange={(e) => setProject({ ...project, skills: e })} options={skills} />
            </div>

            <div className="mb-4">
            <label className="form-label">Select Client</label>
            <Select
                allowClear style={{width: '100%'}} placeholder="Please select" size="large" options={businesses}
                onChange={(e) => {
                     const business = businesses.find(b => b.value === e);
                     setProject({ ...project, client: business.label, business_user_id: business.value });
                }} />
            </div>

            <p className="lead mt-5"><i className='bi bi-geo'/> Milestones</p>
            {project.milestones.map((milestone, index) => (
            <div key={index} className="p-3 mb-3">
                <div className="mb-4">
                    <label className="form-label">Milestone Name</label>
                    <input type="text" className="form-control" value={milestone.name} onChange={(e) => {
                        const newMilestones = [...project.milestones];
                        newMilestones[index].name = e.target.value;
                        setProject({ ...project, milestones: newMilestones });
                    }} />
                    <p className="text-small float-end" style={{cursor: 'pointer'}} onClick={() => handleAddTask(index)}><i className='bi bi-plus-circle'/> Add Task</p>
                </div>

                {milestone.tasks.map((task, taskIndex) => (
                    <div key={taskIndex} className="mt-2">
                        <div className="mb-3">
                            <label className="form-label">Task Name</label>
                            <input type="text" className="form-control" value={task.name} onChange={(e) => {
                                const newMilestones = [...project.milestones];
                                newMilestones[index].tasks[taskIndex].name = e.target.value;
                                setProject({ ...project, milestones: newMilestones });
                                }} 
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Task Hours</label>
                            <input type="number" className="form-control" value={task.budget} onChange={(e) => {
                                const newMilestones = [...project.milestones];
                                newMilestones[index].tasks[taskIndex].budget = e.target.value;
                                setProject({ ...project, milestones: newMilestones });
                                }} 
                            />
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Task Due Date</label>
                            <DatePicker className="w-100" onChange={(date, dateString) => {
                                const newMilestones = [...project.milestones];
                                newMilestones[index].tasks[taskIndex].due_date = dateString;
                                setProject({ ...project, milestones: newMilestones });
                                }} 
                            />
                        </div>
                        
                    </div>
                ))}
            </div>
            ))}

            <p className="text-small" style={{cursor: 'pointer'}} onClick={handleAddMilestone}><i className='bi bi-plus-circle'/> Add Milestone</p>
            
            {project.milestones.length > 0 ? <div className="d-flex">
                <button className="btn btn-primary btn-small mt-5 w-100" onClick={fetchSuggestedProfessionals}>Suggest Professionals</button>
                {isSummary ? <button className="btn btn-primary btn-small mt-5 w-100" onClick={previewProjectSummary} style={{marginLeft: '10px'}}>Preview Estimate</button>: ''}
            </div> : ''}

            
            {isSuggestProfessional && isSummary ? (
                <div>
                    <div className='mt-4'>
                        <div className="bg-light-blue p-3 mb-3">
                            <p className="mb-0 fw-bold">Summary of this Project</p>
                        </div>
                        {project.milestones.map((milestone, index) => {
                            let hoursRequired = 0;
                            milestone.tasks.map(item => {
                                hoursRequired += parseInt(item.budget)
                            })

                            return (
                            <div key={index} className="mb-4">
                                <h6 className="fw-bold">Milestone {index + 1}</h6>
                                <div className="row">
                                <div className="col-6">
                                    <p>Tasks</p>
                                </div>
                                <div className="col-6 text-end">
                                    <p>Total: {milestone.tasks.length}</p>
                                </div>
                                <div className="col-6">
                                    <p>Hours Required</p>
                                </div>
                                <div className="col-6 text-end">
                                    <p>Total: {hoursRequired}</p>
                                </div>
                                <div className="col-6">
                                    <p>Professional Assigned</p>
                                </div>
                                <div className="col-6 text-end">
                                    <p>{professionals.find(item => item.id == milestone.user_id)?.name}</p>
                                </div>
                                <div className="col-6">
                                    <p>Hourly Rate</p>
                                </div>
                                <div className="col-6 text-end">
                                    <p>{professionals.find(item => item.id == milestone.user_id)?.subProfessionalProfile?.compensation}</p>
                                </div>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-between">
                                <h6 className="fw-bold">Total Expense of Milestone {index + 1}</h6>
                                <h6 className="text-success">Total: ${parseFloat(extractNumber(professionals.find(item => item.id == milestone.user_id)?.subProfessionalProfile?.compensation)) * hoursRequired}</h6>
                                </div>
                            </div>
                            );
                        })}
                    </div>
                    <Spin spinning={loading}>
                        <button className="btn btn-primary btn-small w-100 mt-5" onClick={handleSubmit}>Create Project</button>
                    </Spin>
                </div>
            ) : ''}
            
        </div>
        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
            {project.milestones.length > 0 ? <p className="lead fw-bold">Professionals Suggested</p> : ''}
            {project.milestones.map((milestone, index) => 
                <div className="mb-4">
                    <p className=""><i className="bi bi-geo"/> Milestone {index + 1}</p>
                    {professionals.length > 0 ? professionals.map((subProfessional) => 
                    <div className="shadow-sm bg-white p-3 mb-3" key={subProfessional?.id}>
                        <div className="row">
                            <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                                <div className="d-flex">
                                    <Avatar size="medium" src={`https://i.pravatar.cc/40?u=${subProfessional?.name}`} style={{minHeight: '40px',minWidth: '40px'}} />
                                    <div style={{marginLeft: '10px'}}>
                                        <p className="mb-1 fw-bold">{subProfessional?.name}</p>
                                        <p className="text-small text-blue fw-bold">Pay: {subProfessional?.subProfessionalProfile?.compensation}</p>
                                        <div>
                                            {subProfessional?.subProfessionalProfile.skills.map((skill) => <Tag className="mb-2" key={skill.id}>{skill.name}</Tag>)}
                                        </div>
                                        <button className="btn btn-small btn-primary mt-3 w-50" onClick={() => assignProfessionalToMilestone(index, subProfessional?.id)}>
                                            {milestone.user_id === subProfessional.id ? 'Assigned' : 'Assign to Milestone'}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <div style={{ textAlign: "center" }}>
                                    <Progress
                                        className="suggestedProgress"
                                        type="circle"
                                        percent={Math.round(parseFloat(calculateMatchPercentage(project.skills.length, subProfessional?.subProfessionalProfile.skills.length)))}
                                        strokeColor="#00C083"
                                        trailColor="#D9D9D9"
                                    />
                                    <div style={{ marginTop: 8, fontSize: 13, fontWeight: 500 }}>Match</div>
                                </div>
                            </div>
                        </div>
                    </div>) : <p>No professional matches the skillset for this project!</p>}
                </div>
            )}
            
        </div>
      </div>
    </div>
  );
};