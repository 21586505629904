import {notification} from "antd";
import secureLocalStorage from "react-secure-storage";

export function notifyUser(
    type = "success",
    message,
    title = "Notification",
    placement = "bottomRight"
) {
    if (type == "error") {
        notification.error({
            message: title,
            description: message,
            placement: placement,
        });
    } else {
        notification.success({
            message: title,
            description: message,
            placement: placement,
        });
    }
}


export function logoutUser() {
    secureLocalStorage.removeItem('loggedInToken')
    window.location.href = "/login";
}

export function getUserToken(){
    return secureLocalStorage.getItem('loggedInToken') ?? null;
}

export function getUserDetails(){
    return JSON.parse(secureLocalStorage.getItem('loggedInUser'));
}

export function isSubscribed(){
    return JSON.parse(secureLocalStorage.getItem('loggedInUser'))?.isSubscriptionActive;
}

export let currencyFormatter = (num) => {
    let split = "";
    if (num) {
        split = parseFloat(num).toFixed(2).toString().split(".");
        split[0] = split[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        split = split.join(".");

        return split === 0 ? 0 : split;
    } else {
        return 0;
    }
};

export let isFormComplete = (form) => {
    return Object.values(form).every(value => value.trim() !== '');
};

export const getFileNameFromUrl = (url) => {
    return url.substring(url.lastIndexOf('/') + 1);
};

export const removePublicFromUrl = (url) => {
    return url.replace('/public', '');
};

export const getURLParameters = (variable) => {
    var query = window.location.search.substring(1);
        // console.log(query)
        var vars = query.split("&");
        for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
        if(pair[0] == variable){return pair[1];}
    }
    return (false);
}

export function calculateMatchPercentage(matchedSkills, totalSkills) {
    if (totalSkills === 0) return 0; // Avoid division by zero
    if(((matchedSkills / totalSkills) * 100) > 99) return 99;
    return ((matchedSkills / totalSkills) * 100).toFixed(2); // Round to 2 decimal places
}

export function extractNumber(str) {
    const match = str.match(/\d+/); // Find the first sequence of digits
    return match ? parseInt(match[0], 10) : null;
}