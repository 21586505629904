import React from "react";
import {Link} from "react-router-dom";
import InitialsAvatar from 'react-initials-avatar';
import 'react-initials-avatar/lib/ReactInitialsAvatar.css';
import {serverRequest} from "../../Utils/server_request";
import anvillistlogo from "../../assets/anvillist-logo.png";
import {getUserDetails, getUserToken, logoutUser} from "../../Utils/general";

export const TemplateContainer = (props) => {

    function signOut() {
        serverRequest('POST', 'logout').then(result => {
            logoutUser()
            window.location.href = '/login';
        })
    }

    return (
        <>
            <nav className={`navbar navbar-expand-lg bg-white navbar-light bg-light ${getUserToken() ? 'shadow': ''}`}>
                <div className="container-fluid">
                    <a className="navbar-brand" href="#"><img src={anvillistlogo} alt="logo" height='50px'/></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarResponsive">
                        {getUserToken() ?
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <a className={`nav-link ${window.location.pathname === '/dashboard' ? 'active' : ''}`} href="/dashboard">Dashboard</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${window.location.pathname === '/workforce' ? 'active' : ''}`} href="/workforce">Workforce</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${window.location.pathname === '/projects' ? 'active' : ''}`} href="/projects">Projects</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${window.location.pathname === '/business' ? 'active' : ''}`} href="/business">Business List</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${window.location.pathname === '/meetings' ? 'active' : ''}`} href="/meetings">Meetings</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${window.location.pathname === '/data-room' ? 'active' : ''}`} href="/data-room">Data Room</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${window.location.pathname === '/inbox' ? 'active' : ''}`} href="/inbox">Inbox</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${window.location.pathname === '/pricing' ? 'active' : ''}`} href="/pricing">Pricing</a>
                                </li>
                            </ul>
                            :
                            ''
                        }

                        <ul className="navbar-nav ms-auto">
                            {getUserToken() ?
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                       data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <InitialsAvatar name={getUserDetails()?.name} /> {getUserDetails()?.name}
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end animate slideIn"
                                         aria-labelledby="navbarDropdown">
                                        <a className="dropdown-item" href="/profile">My Profile</a>
                                        <a className="dropdown-item" href="/meetings">Meetings</a>
                                        <div className="dropdown-divider"/>
                                        <a className="dropdown-item" href="#" onClick={signOut}>Logout</a>
                                    </div>
                                </li>
                                : <></>
                            }
                        </ul>
                    </div>
                </div>
            </nav>
            <section className="py-5">
                <div className="container">
                    {props.children}
                </div>
            </section>
        </>
    )
}