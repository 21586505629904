import React from "react"
import { getUserDetails } from "../../Utils/general"
import { Link } from "react-router-dom"
import dash1 from "../../assets/dashboard/dash1.png"
import dash2 from "../../assets/dashboard/dash2.png"
import dash3 from "../../assets/dashboard/dash3.png"
import dash4 from "../../assets/dashboard/dash4.png"
import dash5 from "../../assets/dashboard/dash5.png"

export const DashboardPage = (props) => {

    return (
        <div>
            <div className="mx-auto mobile-full-width" style={{width:'90%'}}>
            {/*<h3 className="text-center font-weight-bold">Welcome to Anvil List, your trusted partner on the path to investment readiness.</h3>*/}
            {/*<p className="lead text-center mb-4">For the best experience, we recommend taking our assessment and setting up your profile. This will help us identify the gaps in your business and work towards closing them.</p>*/}
            <p className="mb-1 mt-5 text-small">👋🏼 Hey {getUserDetails()?.name},</p>
            <h3 className="fw-bold mb-5">Look at the endless possibilities to grow your business</h3>
        </div>
        <div className="mx-auto mobile-full-width mt-5" style={{width:'90%'}}>
            <div className="row">
                <div className="col-lg-3 col-md-3 mb-4 col-xs-12 col-sm-12 mt-3">
                    <div className="shadow p-4 dashboard-card">
                        <img src={dash3} alt="card img" className='img-fluid' width='80px'/>
                        <div className="dashboard-card-text">
                            <p className="lead fw-bold mt-4">Take on Projects</p>
                            <p className="">To get the most out of our platform, please visit projects page andd assign yourself to one.</p>
                        </div>
                        <a href="/projects" className="btn btn-small btn-primary">Access <i className="bi bi-arrow-right"/></a>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 mb-4 col-xs-12 col-sm-12 mt-3">
                    <div className="shadow p-4 dashboard-card">
                        <img src={dash2} alt="card img" className='img-fluid' width='80px'/>
                        <div className="dashboard-card-text">
                            <p className="lead fw-bold mt-4">Workforce</p>
                            <p className="">You can invite other professionaals to work under you to help complete projects at faster pace</p>
                        </div>
                       <a href="/workforce" className="btn btn-small btn-primary">Access <i className="bi bi-arrow-right"/></a>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 mb-4 col-xs-12 col-sm-12 mt-3">
                    <div className="shadow p-4 dashboard-card">
                        <img src={dash4} alt="card img" className='img-fluid' width='80px'/>
                        <div className="dashboard-card-text">
                            <p className="lead fw-bold mt-4">Get Subscribed</p>
                            <p className="">Subscribing to one of our package provides limitless access to tools and features to make more income.</p>
                        </div>
                        <a href="/pricing" className="btn btn-small btn-primary">Access <i className="bi bi-arrow-right"/></a>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 mb-4 col-xs-12 col-sm-12 mt-3">
                    <div className="shadow p-4 dashboard-card">
                        <img src={dash1} alt="card img" className='img-fluid' width='80px'/>
                        <div className="dashboard-card-text">
                            <p className="lead fw-bold mt-4">Data Room</p>
                            <p className="">Access uploaded and stored documents by SMEs seeking funding. You can only visit room when invited.</p>
                        </div>
                        <a href="/data-room" className="btn btn-small btn-primary">Access <i className="bi bi-arrow-right"/></a>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 mb-4 col-xs-12 col-sm-12 mt-3">
                    <div className="shadow p-4 dashboard-card">
                        <img src={dash5} alt="card img" className='img-fluid' width='80px'/>
                        <div className="dashboard-card-text">
                            <p className="lead fw-bold mt-4">Update Profile</p>
                            <p className="">Complete or  update your profile to see list of remediation projects to take on for SMEs.</p>
                        </div>
                        <a href="/profile" className="btn btn-small btn-primary">Access <i className="bi bi-arrow-right"/></a>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}