import React, {useEffect, useState} from "react";
import {getUserDetails, notifyUser} from "../../Utils/general";
import {Input, Spin} from 'antd';
import {SingleFile} from "./SingleFile";
import {serverRequest} from "../../Utils/server_request";
import { useLocation } from "react-router-dom";

export const RoomFileList = () => {

    const location = useLocation()
    console.log(location)
    const business_name = location.state.business_name;
    const business_id = location.state.business_id;
    const [searchTerm, setSearchTerm] = useState('');
    const [documents, setDocuments] = useState([]);
    const [filteredDocs, setFilteredDocs] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getDocuments()
    },[])

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);

        if (searchTerm.length > 2){
            const filteredDocuments = documents.filter((document) =>
                document.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredDocs(filteredDocuments);
        }else{
            setFilteredDocs([])
        }
    };

    const getDocuments = () => {
        setLoading(true)
        serverRequest('GET', `professional/data-room/get-files/${business_id}`)
            .then(result => {
                setLoading(false)
                if (result.documents){
                    setDocuments(result.documents)
                }
            }).catch(error =>{
            setLoading(false)
            notifyUser('error', error.message, 'Failed')
        })
    }

  return (
      <div className=''>
        <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <p className="fw-bold lead mb-0">Data Room for {business_name}</p>
                <small style={{cursor:'pointer'}} className='text-success fw-bold'><i className="bi bi-patch-check-fill"/> All documents uploaded by {business_name}</small>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <Input size="large" placeholder="Search your files by category" prefix={<i className='bi bi-search'/>} suffix={<i className='bi bi-sliders'/>}
                       value={searchTerm}
                       onChange={handleSearchChange} />
            </div>
        </div>
        <div className="" style={{paddingTop:'8%'}}>
            <div className="row">
                    {filteredDocs.length > 0 && filteredDocs.map(item =>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-4" key={item.id}>
                        <SingleFile title={item.name} subTitle='Created 1min ago' file={item.files} status={item.status}/>
                    </div>
                )}
                {filteredDocs.length < 1 && documents.map(item =>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-4" key={item.id}>
                        <SingleFile title={item.name} subTitle='Created 1min ago' file={item.files} status={item.status}/>
                    </div>
                )}
            </div>
        </div>
      </div>
  )
}