import React, {useEffect, useState} from "react";
import { Table, Progress, Tag, Drawer, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import { serverRequest } from "../../Utils/server_request";
import { Pagination } from "../../Components/Pagination";
import { ProjectDetails } from "./projectDetails";
import { notifyUser } from "../../Utils/general";

const { TabPane } = Tabs;

export const ProjectPage = () => {

  const [data, setData] = useState([])
  const [meta, setMeta] = useState({});
  const [singleProject, setSingleProject] = useState({});
  const [drawer, setDrawer] = useState(false);

  const [approvedData, setApprovedData] = useState([])
  const [approvedMeta, setApprovedMeta] = useState({});

  const navigate = useNavigate();

  const openSingle = (data) => {
    // console.log(data)
    setSingleProject(data)
    setDrawer(true)
  }

const columns = [
  {
    title: "Project Name",
    dataIndex: "title",
    key: "title",
    render: (text, record) => (
      <div className="d-flex align-items-center">
        <i className={record.icon + " fs-4 me-2"}></i>
        <div>
          {text}
        </div>
      </div>
    ),
  },
  {
    title: "Industry",
    dataIndex: "industry",
    key: "industry",
    render: (text, record) => (
      <strong>{record.industry}</strong>
    )
  },
  // {
  //   title: "Client",
  //   dataIndex: "client_name",
  //   key: "client_name",
  //   render: (text, record) => (
  //     <strong>{record.client_name.toUpperCase()}</strong>
  //   ),
  // },
  {
    title: "Milestone",
    dataIndex: "milestones",
    key: "milestones",
    render: (text, record) => (
      <div>{record.milestones.length} milestones</div>
    ),
  },
  {
    title: "Progress",
    dataIndex: "completion_level",
    key: "completion_level",
    render: (progress) => <Progress percent={progress} status="active" strokeColor="#6bc497"/>,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => {
      const statusColors = {
        pending: "gold",
        approved: "blue",
        completed: "green",
        closed: "red",
      };
      return <Tag color={statusColors[status] || "default"}>{status.toUpperCase()}</Tag>;
    },
  },
  {
    title: "Deadline",
    dataIndex: "due_date",
    key: "due_date",
  },
  {
    title: "Action",
    dataIndex: "ation",
    key: "ation",
    render: (_, record) => <button className="btn btn-small btn-dark" onClick={() => openSingle(record)}>View</button>
  },
];

useEffect(() => {
  fetchProjects()
  fetchApprovedProjects()
},[])

const fetchProjects = (page = 1) => {
  serverRequest('GET', `professional/business-recommendations?page=${page}`).then(result => {
    if(result.data){
      setData(result.data)
      setMeta(result.meta);
    }else{
      notifyUser('error', result?.error, 'Error')
    }
  })
}

const fetchApprovedProjects = (page = 1) => {
  serverRequest('GET', `professional/projects/approved?page=${page}`).then(result => {
    if(result.data){
      setApprovedData(result.data)
      setApprovedMeta(result.meta);
    }else{
      notifyUser('error', result?.error, 'Error')
    }
  })
}

  return (
    <div className="">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Remmediations" key="1">
          <div className="d-flex justify-content-between align-items-center mb-3">
          <p className="lead">Available Projects</p>
          {/* <button className="btn btn-primary btn-small" onClick={() => navigate("/projects/create")}>Create a Project</button> */}
        </div>
        <Table columns={columns} dataSource={data} pagination={false} />
          
        <div className="mt-4">
          <Pagination
              currentPage={meta.current_page}
              perPage={meta.per_page}
              total={meta.total}
              onPageClick={fetchProjects}/>
        </div>

        </TabPane>
        <TabPane tab="Approved Projects" key="2">
          <p className="lead">Ongoing Projects for You</p>
          <Table columns={columns} dataSource={approvedData} pagination={false} />
          <div className="mt-4">
              <Pagination
                  currentPage={approvedMeta.current_page}
                  perPage={approvedMeta.per_page}
                  total={approvedMeta.total}
                  onPageClick={fetchApprovedProjects}/>
          </div> 
        </TabPane>
      </Tabs>

      <Drawer open={drawer} onClose={() => setDrawer(false)} footer={null} destroyOnClose={true} width='800px' title={`Project`}>
          <ProjectDetails data={singleProject} />
      </Drawer>
    </div>
  );
};