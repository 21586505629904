import React, { useState, useEffect } from 'react';
import { Tag, Spin, Drawer, Space, Input, Table} from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { serverRequest } from '../../Utils/server_request';
import { getUserDetails, notifyUser } from '../../Utils/general';
import dayjs from 'dayjs';

export const WorkforcePage = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [originalData, setOriginalData] = useState([]);
    const [show, setShowing] = useState(false);
    const [emails, setEmails] = useState([""]);

    const toggleLoading = () => setLoading(prevState => !prevState)
    const toggleModal = () => setShowing(prevState => !prevState)

    useEffect(() =>{
        fetchData()
    },[])

    const fetchData = () => {
        toggleLoading()
        serverRequest('GET', `get-all-sub-professionals`).then(result =>{
            if (result){
                toggleLoading()
                setData(result?.data);
                setOriginalData(result?.data);
            }
        }).catch(error => {
            notifyUser('error', error.message, 'Data fetching failed')
        })
    }

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchText(value);

        const filteredData = originalData.filter(item =>
            Object.values(item).some(val =>
                String(val).toLowerCase().includes(value)
            )
        );
        setData(filteredData);
    };

    const columns = [
        { title: 'Created On', dataIndex: 'created_at', key: 'created_at', onFilter: (value, record) => record.createdBy.includes(value), render: (created_at) => dayjs(created_at).format('YYYY-MM-D')},
        { title: 'name', dataIndex: 'name', key: 'name', onFilter: (value, record) => record.createdBy.includes(value), },
        {title: 'Status', dataIndex: 'phone', key: 'phone', render: (phone) => (<Tag color={phone !== '020000000000' ? 'green' : 'red'}>{phone !== '020000000000' ? 'Active' : 'Inactive'}</Tag>), },
        { title: 'email', dataIndex: 'email', key: 'email' },
        { title: 'phone', dataIndex: 'phone', key: 'phone' },
         { title: 'Action',render: (_, record) => <button className='btn btn-dark btn-small' disabled={record.phone === '020000000000'}>View Profile</button> },
    ];

    const addEmailField = () => {
        setEmails([...emails, ""]);
    };

    const handleEmailChange = (index, value) => {
        const newEmails = [...emails];
        newEmails[index] = value;
        setEmails(newEmails);
    };

    const submitInvite = () => {
        console.log(emails)
        if(emails.length  < 1){
            return notifyUser('error', 'Please enter at least one email', 'Validation')
        }
        toggleLoading()
        serverRequest('POST', `professional/${getUserDetails()?.id}/invite-sub-professional`, {emails}).then(result => {
            toggleLoading()
            if(result?.success){
                notifyUser('success', 'Professionals invited successfully', 'Sucesss')
                toggleModal()
                fetchData()
            }else{
                notifyUser('error', 'Problem inviting professionals', 'Error')
            }
        })
    }

    return (
        <div>
            <div className="p-4">
                <Space style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <p className="lead">Workforce</p>
                    </div>
                    <Space>
                        <Input
                            prefix={<SearchOutlined />}
                            placeholder="Search"
                            style={{ width: 200 }}
                            value={searchText}
                            onChange={handleSearch}
                            allowClear
                        />
                        <button className='btn btn-primary btn-small' onClick={toggleModal}>Invite Prrofessional</button>
                    </Space>
                </Space>
                <Table
                    columns={columns}
                    dataSource={data}
                    rowKey={(record) => record.id}
                    loading={loading} 
                    pagination={false}/>
             </div>
            <Drawer open={show} onClose={toggleModal} footer={null} destroyOnClose={true} width='500px'>
                <Spin spinning={loading}>
                    <p className='lead'>Invite Professionals</p>
                    {emails.map((email, index) => (
                        <input
                        key={index}
                        type="email"
                        value={email}
                        onChange={(e) => handleEmailChange(index, e.target.value)}
                        placeholder='Please enter email address'
                        className="form-control mb-4"
                        />
                    ))}
                    <small onClick={addEmailField} style={{cursor: 'pointer'}} className='float-end'><i className='bi bi-plus-circle'/> Add Email</small>
                    <button className='btn btn-primary btn-small mt-5 w-100' onClick={submitInvite}>Send Invitation</button>
                </Spin>
            </Drawer>
        </div>
    )
    
};