import React from 'react';
import ReactDOM from 'react-dom/client';
import './CSS/createProject.css';
import './index.css';
import { router } from './router';
import {RouterProvider} from 'react-router-dom';
import {TemplateContainer} from "./Components/v1/TemplateContainer";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <TemplateContainer>
      <RouterProvider router={router} />
  </TemplateContainer>
);
