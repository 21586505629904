import React from "react";
import InitialsAvatar from "react-initials-avatar";

export const SingleFile = ({ title, file, status }) => {

    const downloadFile = (fileName, fileUrl) => {
        const link = document.createElement('a');
        link.href = `https://api.anvillist.com/docs/${fileUrl}`;
        link.download = `${fileName}-${fileUrl}`;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

  return (
      <div className="file-grey-box p-3">
          <div className="dot-small-card mb-3 float-end" style={{cursor:'pointer'}}>
              <i className="bi bi-download" data-bs-toggle="dropdown" aria-expanded="false" />
              <ul className="dropdown-menu">
                  {file.map((item, index) => <li><small className="dropdown-item" onClick={() => downloadFile(title, item)}>Download File {index + 1}</small></li>)}
              </ul>
          </div>
          <div>
              <div className='mt-4 mb-3'>
                  <p className="fw-bold mb-0">{title}</p>
                  <small className="text-black-50" style={{fontSize:'11px'}}>has {file?.length} file{file?.length > 1 ? 's': ''}</small>
              </div>
              <div className="shadow-sm bg-white p-3 rounded" style={{height: '100px'}}>
                  <InitialsAvatar name={title} />
              </div>
          </div>
      </div>
  )
}